.wit-container {
  max-height: 75vh;
  height: 75vh;
  flex-direction: column;
  margin-top: 30px;
  margin-left: 8vw;
}

.wit-elements-container {
  display: flex;
  z-index: 1 !important;
}

.wit-element {
  width: 40vw;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start !important;
  justify-content: center;
  margin-top: 0.75rem;
  font-weight: normal;
}

.wit-element.left {
  margin-right: 1rem;
  display: block !important;
  margin-top: 11rem;
}

.wit-element img {
  margin-left: 5vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 35vw;
  height: auto;
}

.wit-element.right {
  margin-left: 1rem;
}

.wit-title {
  color: #82c051;
  font-size: 2.25rem;
  font-weight: bold;
}

.wit-subtitle {
  font-size: 4rem;
  font-weight: bold;
}

.wit-body {
  margin-top: 20px;
  font-size: 2rem;
  line-height: 2;
  color: #656665;
  font-weight: 600;
}
