.section2-container {
  max-width: 100vw;
  width: 100vw;
  max-height: 80vh;
  height: 80vh;
  overflow: hidden;
  position: relative;
}
.section2-background {
  background-image: url("../../assets/images/section2-background.jpg");
  background-size: cover;
  background-position: center;
  min-height: 100%;
  height: 100%;
  width: 150vw;
  margin-left: -25vw;
  position: absolute;
  border-radius: 50%;
  z-index: -1;
}

.section2-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 50%;
  margin-left: 25%;
}
.section2-container p {
  color: white;
  font-size: 2rem;
  text-align: center;
}
.section2-icons {
  display: flex;
  margin-top: 3rem;
}
.section2-icons .section2-icon {
  padding: 2rem;
  margin: 0 1rem;
  border-radius: 50%;
}
