.section-5 {
  margin: 10rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.section-5 hr {
  border-color: #0a98b0;
  width: 50%;
}
.section-5 p {
  font-size: 20px;
  margin-top: 3rem;
  text-align: center;
  width: 60%;
}
