ul {
  list-style-type: none;
}

li {
  margin-bottom: 30px;
  font-size: 1.5rem;
  transition: transform 0.75s;
}

li:hover {
  filter: drop-shadow(0 10px 5px rgba(0, 0, 0, 0.35));
  transform: translateX(0px) translateY(-2px);
}

.cent-pin-img {
  max-width: 25vw;
  width: auto;
  height: 22vh;
}

.smaller-cent-pin-img {
  height: 18vh !important;
  max-width: 25vw;
  width: auto;
}

.bigger-cent-pin-img {
  height: 25vh !important;
  max-width: 25vw;
  width: auto;
}

.cent-container {
  height: 100vh;
  flex-direction: column;
  background-color: white;
}

.cent-title {
  color: #19a255;
  font-size: 5rem;
  justify-content: middle;
  text-align: center;
}

.cent-elements-container {
  display: flex;
  justify-content: center;
}

.cent-element {
  width: 40vw;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  justify-content: center;
  margin-top: 0.75rem;
  font-weight: normal;
}

.cent-titular {
  background-color: #19a255;
  font-weight: bold;
}

.cent-element.middle img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: auto;
  height: 80vh;
}

a {
  color: black;
}
