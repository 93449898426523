.section-3 {
  display: flex;
  width: 100vw;
  margin-top: 10rem;
}

.section-3 .background-img-1 {
  width: 35%;
  background-image: url("../../assets/images/section3bg1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.section-3 .background-img-2 {
  width: 25%;
  background-image: url("../../assets/images/section3bg2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.section-3 .text {
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 5rem 5%;
}
.section-3 .text h1 {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 1rem;
}
.section-3 .text p {
  font-size: 15px;
}
.section-3 .text hr {
  border-color: #0a98b0;
  width: 60%;
  margin-bottom: 3rem;
}
