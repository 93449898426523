.how-container {
  flex-direction: row !important;
  margin-top: 30px;
  margin-left: 8vw;
  margin-bottom: 10vh;
}

.how-elements-container {
  display: row;
  justify-content: left;
  z-index: 1 !important;
}

.how-title {
  font-size: 4rem;
  font-weight: bold;
  width: 100%;
}

.how-body {
  margin: 10px 0;
  font-size: 2rem;
  line-height: 2;
  color: #656665;
  font-weight: 600;
}

.how-columns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 6vh;
  font-weight: normal;
  text-align: left !important;
  width: 80vw;
}

.how-columns .how-title {
  font-size: 3rem;
}

.how-columns img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 40vw;
  height: auto;
}

.how-left {
  margin-right: 2vw;
}

.how-right {
  margin-left: 2vw;
}
