.container-s1 {
  min-height: calc(50vh + 100px);
  padding-top: 150px;
  margin-bottom: 50px;
}
.items-container {
  display: flex;
  justify-content: center;
}

.item {
  background-color: #175762;
  border-radius: 1rem;
  padding: 2.5rem;
  width: 35vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: white;
  font-size: 1.75rem;
  line-height: 2.5rem;
  margin-top: 4rem;
  transition: transform 0.75s;
}

.item.left {
  margin-right: 4rem;
}
.icon.left {
  margin-right: 6.75rem;
}
.item.right {
  margin-left: 4rem;
}
.icon.right {
  margin-left: 6.75rem;
}

.icon {
  width: 30vw;
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  transition: transform 0.75s;
}

.icon:hover {
  filter: drop-shadow(0 10px 5px rgba(0, 0, 0, 0.35));
  transform: translateX(0px) translateY(-2px);
}

.item:hover {
  filter: drop-shadow(0 10px 5px rgba(0, 0, 0, 0.35));
  transform: translateX(0px) translateY(-2px);
}
