.leaf-offer {
  display: grid;
  align-items: center;
  width: 20%;
  height: 120px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../assets/images/ucrop-ofrecemos.png');
}

.offer-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-right: 10vw;
  margin-top: 20px;
}

.leaf-text {
  font-size: 1.75rem;
  line-height: 2;
  color: #656665;
  font-weight: 600;
}

.extra-offer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10vw;
  margin-top: 10vh;
}

.extra-offer-title {
  font-size: 3rem;
  font-weight: bold;
}

.how-element-right {
  margin-left: 1rem;
  margin-left: 5vw;
}
.how-element-right img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 35vw;
  height: auto;
}
